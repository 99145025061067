import { API_URL } from "@/_plugins/axios";
import withoutWatchersMixin from "@/_plugins/withoutWatchersMixin";
import debounce from "lodash/debounce";
import Form from "@/_plugins/form";
import Pagination from "laravel-vue-pagination";
import HasError from "@/components/HasError";
import i18n from "@/_plugins/i18n";

export default {
  mixins: [withoutWatchersMixin],
  name: "Subcategory",
  components: {
    Pagination,
    HasError,
  },
  data() {
    return {
      categories: [],
      deleteId: null,
      item: null,
      form: new Form({
        name: "",
      }),
      currentPage: 1,
      loading: false,
      deleting: false,
      modalTitle: "Dodaj potkategoriju",
      filters: {
        term: "",
      },
    };
  },
  methods: {
    edit(item) {
      this.modalTitle = "Izmijeni potkategoriju";
      this.item = item;
      this.form.id = item.id;
      this.form.name = item.name;
      this.$bvModal.show("modal-1");
    },
    deleteE(item) {
      this.deleteId = item.id;
      this.$bvModal.show("deleteEquipment");
    },
    deleteCategory() {
      this.deleting = true;
      API_URL.delete(`subcategories/${this.deleteId}`)
        .then(() => {
          this.$toast.success(i18n.tc("notifications.success"));
          this.deleting = false;
          this.deleteId = null;
          this.currentPage = 1;
          this.$bvModal.hide("deleteEquipment");
          this.getAllCategories();
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
          this.deleting = false;
          this.$bvModal.hide("deleteEquipment");
        });
    },
    closeModal() {
      this.form.reset();
      this.deleteId = null;
      this.form.errors.errors = {};
      this.item = null;
      this.$bvModal.hide("deleteEquipment");
      this.modalTitle = "Dodaj potkategoriju";
    },
    submitForm() {
      if (!this.item) {
        this.form
          .post("subcategories")
          .then(() => {
            this.getAllCategories();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      } else {
        this.form
          .put(`subcategories/${this.item.id}`)
          .then(() => {
            this.getAllCategories();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      }
    },
    getAllCategories() {
      this.loading = true;
      API_URL.get("subcategories", {
        params: {
          page: this.currentPage,
          ...this.filters,
        },
      }).then((response) => {
        this.categories = response.data;
        this.loading = false;
      });
    },
  },
  computed: {
    resultsCount() {
      return this.categories?.data?.length;
    },
  },
  watch: {
    filters: {
      deep: true,
      immediate: false,
      // eslint-disable-next-line no-unused-vars
      handler: debounce(function (value) {
        this.$withoutWatchers(() => (this.currentPage = 1));
        this.getAllCategories();
      }, 250),
    },
    currentPage() {
      this.getAllCategories();
    },
  },
  mounted() {
    this.getAllCategories();
    this.$emit("loadBreadcrumbLink", {
      pageName: "Potkategorije",
    });
  },
};
