import { API_URL } from "@/_plugins/axios";
import withoutWatchersMixin from "@/_plugins/withoutWatchersMixin";
import debounce from "lodash/debounce";
import Form from "@/_plugins/form";
import Pagination from "laravel-vue-pagination";
import HasError from "@/components/HasError";
import i18n from "@/_plugins/i18n";
import InputTag from "vue-input-tag";

export default {
  mixins: [withoutWatchersMixin],
  name: "Category",
  components: {
    // HelloWorld,
    Pagination,
    HasError,
    InputTag,
  },
  data() {
    return {
      editingSubcategoryCurr: null,
      subcategory: null,
      usersEquipment: [],
      equipmentCategories: [],
      equipmentTypes: [],
      subcategories: [],
      users: [],
      categories: [],
      equipment: [],
      deleteId: null,
      item: null,
      requests: {},
      selectedCategory: null,
      selectedEquipmentType: null,
      selectedEquipment: null,
      selectedSubcategory: null,
      form: new Form({
        name: "",
        subcategories: [],
      }),
      subcategoryForm: new Form({
        name: "",
      }),
      value: null,
      currentPage: 1,
      loading: false,
      deleting: false,
      saving: false,
      modalTitle: "Dodaj kategoriju",
      filters: {
        term: "",
      },
    };
  },
  methods: {
    editSubcategory(i) {
      console.log(i);
      this.editingSubcategoryCurr = i;
      this.subcategoryForm.name = i;
    },
    deleteSubcategory(i) {
      let subcategory = this.item.subcategories.find((item) => item.name === i);
      if (confirm("Da li ste sigurni?")) {
        API_URL.delete(`subcategories/${subcategory.id}`)
          .then(() => {
            this.$toast.success(i18n.tc("notifications.success"));
            this.currentPage = 1;
            this.$bvModal.hide("modal-1");

            this.getAllCategories();
          })
          .catch((err) => {
            this.$toast.error(err.response.data);
            this.deleting = false;
            this.$bvModal.hide("modal-1");
          });
      }
    },
    addCategory() {
      this.subcategoryForm.category_id = this.item.id;
      this.subcategoryForm
        .post(`subcategories`)
        .then(() => {
          this.getAllCategories();
          this.$toast.success(i18n.tc("notifications.success"));
          this.$bvModal.hide("modal-1");
          this.subcategoryForm.reset();
        })
        .catch(() => {
          // console.log("ERROR", error.data);
        });
    },
    editingSubcategory(i) {
      return i === this.editingSubcategoryCurr;
    },
    saveSubcategory(i) {
      this.saving = true;
      let subcategory = this.item.subcategories.find((item) => item.name === i);

      this.subcategoryForm
        .put(`subcategories/${subcategory.id}`)
        .then(() => {
          this.getAllCategories();
          this.$toast.success(i18n.tc("notifications.success"));
          this.$bvModal.hide("modal-1");
          this.editingSubcategoryCurr = null;
          this.saving = false;
        })
        .catch(() => {
          // console.log("ERROR", error.data);
        });
    },
    edit(item) {
      this.form.subcategories = [];
      this.modalTitle = "Izmijeni kategoriju";
      this.item = item;
      this.form.id = item.id;
      this.form.name = item.name;
      item.subcategories.forEach((item) => {
        this.form.subcategories.push(item.name);
      });
      this.$bvModal.show("modal-1");
    },
    deleteE(item) {
      this.deleteId = item.id;
      this.$bvModal.show("deleteEquipment");
    },
    deleteCategory() {
      this.deleting = true;
      API_URL.delete(`categories/${this.deleteId}`)
        .then(() => {
          this.$toast.success(i18n.tc("notifications.success"));
          this.deleting = false;
          this.deleteId = null;
          this.currentPage = 1;
          this.$bvModal.hide("deleteEquipment");
          this.getAllCategories();
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
          this.deleting = false;
          this.$bvModal.hide("deleteEquipment");
        });
    },
    closeModal() {
      this.form.subcategories = [];
      this.subcategoryForm.reset();
      this.form.reset();
      this.deleteId = null;
      this.form.errors.errors = {};
      this.item = null;
      this.$bvModal.hide("deleteEquipment");
      this.modalTitle = "Dodaj kategoriju";
    },
    submitForm() {
      if (!this.item) {
        this.form
          .post("categories")
          .then(() => {
            this.getAllCategories();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      } else {
        this.form
          .put(`categories/${this.item.id}`)
          .then(() => {
            this.getAllCategories();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      }
    },
    getAllCategories() {
      this.loading = true;
      API_URL.get("categories", {
        params: {
          page: this.currentPage,
          ...this.filters,
        },
      }).then((response) => {
        this.categories = response.data;
        this.loading = false;
      });
    },
  },
  computed: {
    resultsCount() {
      return this.categories?.data?.length;
    },
  },
  watch: {
    filters: {
      deep: true,
      immediate: false,
      // eslint-disable-next-line no-unused-vars
      handler: debounce(function (value) {
        this.$withoutWatchers(() => (this.currentPage = 1));
        this.getAllCategories();
      }, 250),
    },
    currentPage() {
      this.getAllCategories();
    },
  },
  mounted() {
    this.getAllCategories();
    this.$emit("loadBreadcrumbLink", {
      pageName: "Kategorije",
    });
  },
};
