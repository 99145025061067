import { API_URL } from "@/_plugins/axios";
import withoutWatchersMixin from "@/_plugins/withoutWatchersMixin";
import debounce from "lodash/debounce";
import Multiselect from "vue-multiselect";
import Form from "@/_plugins/form";
import Pagination from "laravel-vue-pagination";
import HasError from "@/components/HasError";
import i18n from "@/_plugins/i18n";
import DetailsModal from "@/components/DetailsModal";
import { mapGetters } from "vuex";

export default {
  mixins: [withoutWatchersMixin],
  name: "Equipment",
  components: {
    // HelloWorld,
    Multiselect,
    Pagination,
    HasError,
    DetailsModal,
  },
  data() {
    return {
      usersEquipment: [],
      equipmentCategories: [],
      equipmentTypes: [],
      subcategories: [],
      users: [],
      equipment: [],
      deleteId: null,
      item: null,
      requests: {},
      selectedCategory: null,
      selectedEquipmentType: null,
      selectedEquipment: null,
      selectedSubcategory: null,
      form: new Form({
        equipment_id: [],
        notes: "",
      }),
      value: null,
      currentPage: 1,
      loading: false,
      deleting: false,
      modalTitle: i18n.tc("requests.add_request"),
      filters: {
        term: "",
      },
    };
  },
  methods: {
    isNew(id) {
      let found = false;
      this.notifications.find((item) => {
        if (item.request_id === id) found = true;
      });
      return found;
    },
    details(i) {
      this.item = i;
      this.$bvModal.show("details");
    },
    setStatusClass(i) {
      switch (i.id) {
        case 1:
          return "waiting";
        case 2:
          return "in-progress";
        case 3:
          return "rejected";
        case 4:
          return "accepted";
      }
    },
    deleteE(item) {
      this.deleteId = item.id;
      this.$bvModal.show("deleteRequest");
    },
    deleteRequest() {
      this.deleting = true;
      API_URL.delete(`request/${this.deleteId}`).then(() => {
        this.currentPage = 1;
        this.getMyRequests();
        this.$toast.success(i18n.tc("notifications.success"));
        this.closeModal();
        this.deleting = false;
      });
    },
    acceptReq(id) {
      this.loading = true;
      API_URL.post(`request-accept/${id}`).then(() => {
        this.$toast.success(i18n.tc("notifications.success"));
        this.loading = false;
        this.getMyRequests();
      });
    },
    rejectReq(id) {
      this.loading = true;
      API_URL.post(`request-reject/${id}`).then(() => {
        this.$toast.success(i18n.tc("notifications.success"));
        this.loading = false;
        this.getMyRequests();
      });
    },
    closeModal() {
      this.form.reset();
      this.$bvModal.hide("deleteRequest");
      this.deleteId = null;
      this.item = null;
      this.modalTitle = i18n.tc("equipment.add_new_equipment");
      this.selectedCategory = null;
      this.selectedSubcategory = null;
      this.selectedEquipment = null;
    },
    submitForm() {
      this.form
        .post("request")
        .then(() => {
          this.getMyRequests();
          this.$toast.success(i18n.tc("notifications.success"));
          this.$bvModal.hide("modal-1");
        })
        .catch(() => {
          // console.log("ASDSD",this.form);
          // console.log("ERROR", error.data);
        });
    },
    getAllEquipment() {
      API_URL.get("all-equipment").then((response) => {
        this.equipment = response.data.data;
      });
    },
    getMyRequests() {
      this.loading = true;
      API_URL.get("requests", {
        params: {
          page: this.currentPage,
          // is_new: true,
          ...this.filters,
        },
      }).then((response) => {
        this.requests = response.data;
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapGetters({
      notifications: "notifications",
    }),
    resultsCount() {
      return this.requests?.data?.length;
    },
  },
  watch: {
    filters: {
      deep: true,
      immediate: false,
      // eslint-disable-next-line no-unused-vars
      handler: debounce(function (value) {
        this.$withoutWatchers(() => (this.currentPage = 1));
        this.getMyRequests();
      }, 250),
    },
    currentPage() {
      this.getMyRequests();
    },
  },
  mounted() {
    this.getMyRequests();
    this.getAllEquipment();
    this.$emit("loadBreadcrumbLink", {
      pageName: "Zahtjevi za popravku opreme",
    });
  },
};
